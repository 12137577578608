import React, { createContext, useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./Views";
import "./App.css";
import { isAuthenticated } from "./authentication/authenticateService";

export const AppContext = createContext();

function App() {
  const firebaseApp = firebase.apps[0];
  const storedContext = localStorage.getItem("appContext");

  const [appInfo, setAppInfo] = useState(() => {
    if (storedContext) {
      try {
        return JSON.parse(storedContext);
      } catch (e) {
        console.error("Error parsing storedContext:", e);
      }
    }
    return {
      user: null,
      org: null,
      loggedIn: false,
      redprint: null,
      userInfo: null,
    };
  });
  

  useEffect(() => {
    //debugger;
    console.log("AppInfo:", appInfo);
    if (appInfo != undefined && isAuthenticated()) {
      console.log("AppInfo:", appInfo);
      localStorage.setItem("appContext", JSON.stringify(appInfo));
    } else {
      localStorage.setItem(
        "appContext",
        JSON.stringify({
          user: null,
          org: null,
          loggedIn: false,
          redprint: null,
          userInfo: null,
        })
      );
    }
  }, [appInfo]);

  return (
    <Router>
      <AppContext.Provider value={{ appInfo, setAppInfo }}>
        <Views />
      </AppContext.Provider>
    </Router>
  );
}

export default App;
