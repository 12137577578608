import React from 'react';
import { numberWithCommas } from '../../common/utils';

function StatBox({
  count, unit, subtext, progressCount, progress,
}) {
  return (
    <div className="header text-ellipsis">
      <span className="flex items-center">
        <h2 className="title text-2xl font-extrabold mr-2">
          +
          {numberWithCommas(count)}
        </h2>
        <h6 className="statUnit text-base text-gray-600">{unit}</h6>
      </span>
      <h5 className="subtitle">{subtext}</h5>
      <h5 className="progress mt-2 w-fit font-bold text-sm h-6 pt-1 pl-2 pr-2 text-center text-ellipsis rounded-lg bg-gray-300 text-gray-700">
        <b>{progressCount}</b>
        {' '}
        {progress}
      </h5>
    </div>

  );
}

export default StatBox;
