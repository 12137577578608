import React from 'react';
import Analytics from './analytics';

function AnalyticComponent() {
  return <Analytics />;
}

const analyticsModule = {
  routeProps: {
    path: '/analytics',
    element: <AnalyticComponent />,
  },
  name: 'Analytics',
};

export default analyticsModule;
