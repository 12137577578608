import React, { useState, useContext, useEffect } from "react";
import { FaRegClock } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { AppContext } from "../../App";
import { getData,addData } from "../../common/utils";
//import { collection, addDoc, getFirestore, getDocs, updateDoc, arrayUnion, doc,query,getDoc, where,setDoc } from "firebase/firestore";
import Alert from "../Settings/Alert";
import { Timestamp } from "firebase/firestore";
function ExpandableButton({ setShowScheduleSend, showScheduleSend, subject, message, onClose,link, selectedGyms = [], onGymsChange }) {
  const [isGymExpanded, setIsGymExpanded] = useState(false);
  const [showMessageSentPopup, setShowMessageSentPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const { appInfo } = useContext(AppContext);
  const [gyms, setGyms] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isGymExpanded && gyms.length === 0) {
      fetchGyms();
    }
  }, [isGymExpanded]);

  const fetchGyms = async () => {
    try {
      const org_id = appInfo.org.org_id;
      const gymsData = await getData(`gyms?org_id=${org_id}`, appInfo.token);
      setGyms(gymsData);
    } catch (error) {
      console.error("Error fetching gyms:", error);
      setError("Error fetching gyms. Please try again later.");
    }
  };

  const handleGymExpandClick = () => {
    setIsGymExpanded(!isGymExpanded);
  };

  const handleSelectionChange = (gymId) => {
    if (!Array.isArray(selectedGyms)) {
      console.error("selectedGyms is not an array");
      return;
    }
    console.log('Selected Gyms:', selectedGyms);
    const isSelected = selectedGyms.includes(gymId);
    const updatedSelectedGyms = isSelected
      ? selectedGyms.filter(id => id !== gymId)
      : [...selectedGyms, gymId];
      console.log(`Gym ${gymId} ${isSelected ? 'deselected' : 'selected'}. Updated selected gyms:`, updatedSelectedGyms);
    if (onGymsChange) {
      onGymsChange(updatedSelectedGyms); 
    } else {
      console.error("onGymsChange is not provided");
    }
  };

  const handleMessageSendClick = async () => {
    if (!subject || !message) {
        setAlertColor("red");
        setPopupMessage("Subject and message cannot be empty");
        setShowMessageSentPopup(true);
        setTimeout(() => setShowMessageSentPopup(false), 2500);
        return;
    }

    const receiver_info = selectedGyms.length > 0 ? selectedGyms : [-1];

    const newNotification = {
        subject: subject,
        message: message,
        receiver_info: JSON.stringify(receiver_info),
        user_id: appInfo.userInfo.u_id,
        org_id: appInfo.org.org_id,
        link: link
    };

    try {
        const responseData = await addData(
            "notifications/sendnotification",
            JSON.stringify(newNotification),
            appInfo.token
        );
        console.log("Notification Sent successfully:", responseData);
        setAlertColor("green");
        setPopupMessage("Message Sent Successfully!");
        setShowMessageSentPopup(true);
        setTimeout(() => {
            setShowMessageSentPopup(false);
            onClose();
            window.location.reload();
        }, 2500);
    } catch (error) {
        console.error('Error sending notification:', error);
        setAlertColor("red");
        setPopupMessage("Failed to Send Message");
        setShowMessageSentPopup(true);
    }
};

  const handleScheduleClick = () => {
    if (!subject || !message) {
      setAlertColor("red");
      setPopupMessage("Please enter subject and message to schedule");
      setShowMessageSentPopup(true);
      setTimeout(() => setShowMessageSentPopup(false), 2500);
      return;
    }
    setShowScheduleSend(true);
  };

  return (
    <div className="flex flex-col items-between">
      {showMessageSentPopup && (
        <Alert
          message={popupMessage}
          onClose={() => setShowMessageSentPopup(false)}
          color={alertColor}
          timeout={2500}
        />
      )}
      <ul className="w-38 text-[0.70rem] font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-white-700 dark:border-gray-100 dark:text-white">
        <li className="w-full px-2 py-1 border-b border-gray-200 rounded-t-lg dark:border-gray-100 flex items-center space-x-4">
          <div
            className="bg-purple-800 hover:bg-purple-600 text-white font-medium text-sm py-2 px-6 rounded focus:outline-none focus:shadow-outline"
            onClick={handleMessageSendClick}
          >
            Send
          </div>
          <div className="cursor-pointer" onClick={handleGymExpandClick}>
            <IoIosArrowDown color="black" size={"20px"} />
          </div>
          <div
            className="cursor-pointer"
            onClick={handleScheduleClick}
          >
            <FaRegClock color="black" size={"20px"} />
          </div>
        </li>
        {isGymExpanded && gyms.map((gym) => (
          <li key={gym.gym_id} className="w-full p-1 border-b border-gray-300">
            <input
              type="checkbox"
              id={gym.gym_id}
              checked={selectedGyms.includes(gym.gym_id)}
              onChange={() => handleSelectionChange(gym.gym_id)}
              className="checked:bg-blue-600 checked:border-transparent"
            />
            <label htmlFor={gym.gym_id} className="ml-2 text-gray-700">
              {gym.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ExpandableButton;