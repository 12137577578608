import React from "react";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const goToPage = (page) => {
    onPageChange(page);
  };

  const handlePreviousPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <div className="pagination flex justify-center mt-4">
      <button 
        className="pagination-btn bg-gray-200 border-white rounded-lg mx-2 w-8 h-8 focus:outline-none"
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          className={`pagination-btn rounded-lg border-white mx-2 w-8 h-8 focus:outline-none ${currentPage === index + 1 ? 'bg-purple-400 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => goToPage(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button 
        className="pagination-btn bg-gray-200 border-white rounded-lg mx-2 w-8 h-8 focus:outline-none"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
}

export default Pagination;
