import { getData, deleteData, updateData, addData } from "../../common/utils";
import { StatusDisplay } from "./video.view";
class VideoService {
  token = "";
  userId = "";
  orgId = "";
  exercises = [];
  gyms = [];
  exercises_listeners = [];
  videos_listeners = [];

  constructor(token, userId, orgId, gym_ids) {
    this.token = token;
    this.userId = userId;
    this.orgId = orgId;
    this.gyms = gym_ids;
  }

  getExerciseName(exerciseId) {
    if (!this.exercises) {
      return "Unknown";
    }
    return (
      this.exercises.find((exercise) => exercise.exercise_id == exerciseId)
        ?.exercise_name ?? "Unknown"
    );
  }

  callExerciseListeners(updatedList) {
    this.exercises_listeners.forEach((listener) => listener(updatedList));
  }

  registerExercisesListListener(listener) {
    this.exercises_listeners.push(listener);
    return this.exercises_listeners.length - 1;
  }

  unRegisterExercisesListListener(index) {
    this.exercises_listeners.splice(index, 1);
  }

  registerVideosListener(listener) {
    this.videos_listeners.push(listener);
    return this.videos_listeners.length - 1;
  }

  unRegisterVideosListener(index) {
    this.videos_listeners.splice(index, 1);
  }

  callVideoListeners(updatedList) {
    this.videos_listeners.forEach((listener) => listener(updatedList));
  }

  sortExerciseList(list) {
    return list.sort((a, b) => {
      if (a.none < b.none) {
        return -1;
      }
      if (a.none > b.none) {
        return 1;
      }
      return 0;
    });
  }

  getExerciseList(gymId, src = "Unknown") {
    return new Promise((resolve, reject) => {
      getData(`exercise/getExercies/${gymId}`, this.token)
        .then((response) => {
          this.exercises = response;
          this.callExerciseListeners(this.sortExerciseList(response));
          console.log("Fetched exercises", response);
          resolve(response);
        })
        .catch((error) => {
          console.log("Error fetching exercise details:", error);
          reject(error);
        });
    });
  }

  getGymData() {
    return new Promise((resolve, reject) => {
      getData(`gymUsers/gyms/${this.userId}/${this.orgId}`, this.token)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("Error fetching gym details:", error);
          reject(error);
        });
    });
  }

  GetVideosForExercise(exercise_id, gymId, status) {
    const mappedStatus = {
      [StatusDisplay.APPROVED]: "approved",
      [StatusDisplay.PENDING]: "pending",
      [StatusDisplay.NONE]: "none",
      [StatusDisplay.ALL]: "all",
      [StatusDisplay.PENDING_DELETE]: "pending_delete",
    };
    return new Promise((resolve, reject) => {
      if (mappedStatus[status] === "none") {
        resolve(this.callVideoListeners([]));
        return;
      }
      getData(
        `/exercise/${gymId}/videos/${exercise_id}/${mappedStatus[status]}`,
        this.token
      )
        .then((response) => {
          resolve(response);
          this.callVideoListeners(response);
        })
        .catch((error) => {
          console.log("Error fetching videos for exercise:", error);
          reject(error);
        });
    });
  }
}

let videoService = null;

export const getVideoService = (
  token = "",
  userId = "",
  orgId = "",
  gym_ids = []
) => {
  if (!videoService) {
    videoService = new VideoService(token, userId, orgId);
  }
  return videoService;
};
