import React from "react";

function Table({ headers, values, headerSize }) {
  const renderHeaders = () => {
    return (
      <thead className="text-sm text-gray-700 uppercase bg-gray-50">
        <tr>
          {headers.map((header, index) => {
            return (
              <th
                key={index}
                scope="col"
                className="px-4 py-2 text-center border border-gray-200"
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderBody = () => {
    return values.map((row, rowIndex) => {
      return (
        <tr
          key={rowIndex}
          className="bg-white border-b border-gray-200"
          style={{ overflow: "hidden", height: "3rem" }}
        >
          {row.map((element, columnIndex) => {
            return (
              <td
                key={columnIndex}
                className="px-4 py-2 text-center border border-gray-200 whitespace-normal"
                style={{ height: "100%", wordWrap: "break-word" }}
              >
                {element}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div
      className={`overflow-y-auto ${
        headerSize ? `h-${headerSize}` : "max-h-[325px]"
      }`}
    >
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 text-gray-400 table-fixed">
        {headers && renderHeaders()}
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
}

export default Table;
