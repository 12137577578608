import analyticsModule from './Analytics';
import communicationModule from './Communication';
import settingsModule from './Settings';
import trainersModule from './Trainers';
import VideosModule from './Videos';

const expModules = {
  0: [  analyticsModule,
    communicationModule,
    settingsModule,
    trainersModule,
    VideosModule],
  1: [settingsModule],
  2: [analyticsModule,
        communicationModule,
        settingsModule,
        trainersModule,
        VideosModule],
  3: [  analyticsModule,
          communicationModule,
          settingsModule,
          trainersModule,
          VideosModule],
  4: [
            settingsModule,
            VideosModule]
}

export default expModules;
