
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import firebase from 'firebase/compat/app';
import App from './App';
import reportWebVitals from './reportWebVitals';


const firebaseConfig = {
  apiKey: "AIzaSyB44M55Rrz8BQW0aYgNtMO_EfAF9gGCwsI",
  authDomain: "redprint-website-test.firebaseapp.com",
  projectId: "redprint-website-test",
  storageBucket: "redprint-website-test.appspot.com",
  messagingSenderId: "575480490793",
  appId: "1:575480490793:web:38bc633eec0e4234a76538",
  measurementId: "G-ZZCM3V6G6S"
};

firebase.initializeApp(firebaseConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
