import React from 'react';
import UserTable from './UserTable';

function Trainers() {
  return <UserTable />;
}

const trainersModule = {
  routeProps: {
    path: '/trainers',
    element: <Trainers />,
  },
  name: 'Trainers',
};

export default trainersModule;
