import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  updateEmail,
} from "firebase/auth";
import Alert from "../modules/Settings/Alert";
import { getData } from "../common/utils";

function VerifyEmail() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [alertTimeout, setAlertTimeout] = useState(3000);

  const navigate = useNavigate();
  const location = useLocation();

  const decryptToken = async (emailToken) => {
    try {
      const decryptedToken = await getData(
        `crypto/decrypt?encryptedData=${emailToken}`
      );
      return decryptedToken;
    } catch (error) {
      console.log("error from decryptToken");
      throw new Error(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailToken = searchParams.get("emailToken");
    const verifyAndUpdateEmail = searchParams.get("verifyAndUpdateEmail");

    const verifyEmail = async (emailToken) => {
      try {
        await getData(`/emails/verifyEmail?emailToken=${emailToken}`);
        setAlertTimeout(5000);
        setAlertColor("green");
        setAlertMessage(
          "Email Verified Successfully! You will be redirected to the landing page shortly"
        );
        setShowSuccessAlert(true);

        setTimeout(() => {
          window.location.href = `/resetPassword?emailToken=${emailToken}`;
        }, 7000);
      } catch (error) {
        console.log("error", error);
        setAlertTimeout(4000);
        setAlertColor("red");
        setAlertMessage(
          "An error has occurred. Please contact info@redprintfit.com if the problem persists."
        );
        setShowSuccessAlert(true);
      }
    };

    const updateUserEmail = async (verifyAndUpdateEmail) => {
      try {
        const decryptedToken = await decryptToken(verifyAndUpdateEmail);
        const auth = getAuth();

        const credential = await signInWithEmailAndPassword(
          auth,
          decryptedToken.email,
          decryptedToken.password
        );

        const { user } = credential;

        await updateEmail(user, decryptedToken.newEmail);
        await getData(`/emails/verifyEmail?emailToken=${verifyAndUpdateEmail}`);
        setAlertMessage("Email updated successfully!");
        setAlertColor("green");
        setShowSuccessAlert(true);
        setTimeout(() => {
          window.location.href = "/";
        }, 7000);
      } catch (error) {
        console.log("Error:", error);
        setAlertMessage(
          "An error has occurred. Please contact info@redprintfit.com if the problem persists."
        );
        setAlertColor("red");
        setShowSuccessAlert(true);
      }
    };

    if (verifyAndUpdateEmail) {
      updateUserEmail(verifyAndUpdateEmail);
    } else {
      verifyEmail(emailToken);
    }
  }, [navigate, location.search]);

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={alertTimeout}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      <div className="flex justify-center items-center h-full">
        <div className="mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <form
            action="#"
            className="w-full p-2"
            style={{
              width: "400px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "50px",
              height: "100%",
              textAlign: "center",
            }}
          >
            <h1 className="font-bold text-2xl mb-2">Verifying Email....</h1>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
