import React from "react";
import Videos from "./video.view";

const VideosModule = {
  routeProps: {
    path: "/videos/*",
    element: <Videos />,
  },
  name: "Videos",
};

export default VideosModule;
