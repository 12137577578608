import React from "react";

const Header = ({ title, subtitle, titleClass, subTitleClass, subtitleStyle }) => {
  return (
    <div className="header">
      <div className="splitClientTitle">
        <h2 className={titleClass}>{title}</h2>
      </div>
      <h5 className={subTitleClass} style={subtitleStyle}>{subtitle}</h5>
    </div>
  );
};

Header.defaultProps = {
  titleClass: "clientTitle w-20",
  subTitleClass: "clientSubtitle bg-gradient-custom",
};

export default Header;
