import React from "react";

function Button(props) {
  const handleClick = () => {
    console.log("Button was clicked!"); 
    if (props.onClick) {
      props.onClick(); 
    }
  };

  return (
    <button type="button" className={props.class} onClick={handleClick}>
      {props.value}
    </button>
  );
}

export default Button;
