import React from 'react';
import Notifications from './allcomponent';

function Communication() {
  return <Notifications />;
}

const communicationModule = {
  routeProps: {
    path: '/communication',
    element: <Communication />,
  },
  name: 'Communication',
};

export default communicationModule;
