import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../common/utils";
import t from "./../../assets/noData/messages.png";
import moment from "moment-timezone";

const NotificationList = ({
startDate,
endDate,
checkedItems,
searchTerm,
scheduled,
}) => {
const [notifications, setNotifications] = useState([]);
const [gyms, setGyms] = useState([]); // State to store gym data
const { appInfo } = useContext(AppContext);
const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 9;

const totalPages = Math.ceil(notifications.length / itemsPerPage);
const pageNumbers = Array.from(
  { length: totalPages },
  (_, index) => index + 1
);

const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const handleFetchedDate = (originalDateString) => {
  if (!originalDateString) return ""; // Handle undefined or null input
  
  let parts = originalDateString.split(" ");
  if (parts.length < 3) return ""; // Handle unexpected format

  let datePart = parts[0];
  let timePart = parts[1];
  let amPm = parts[2];

  let dateComponents = datePart.split("/");
  let month = dateComponents[0];
  let day = dateComponents[1];
  let year = dateComponents[2];

  let timeComponents = timePart.split(":");
  let hour = parseInt(timeComponents[0]);
  let minute = timeComponents[1];

  if (amPm === "pm" && hour < 12) {
    hour += 12;
  } else if (amPm === "am" && hour === 12) {
    hour = 0;
  }

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  hour = hour < 10 ? `0${hour}` : hour;
  minute = minute < 10 ? `0${minute}` : minute;

  let formattedDateString = `${year}-${month}-${day}-${hour}-${minute}`;
  return formattedDateString;
};


const fetchNotifications = async () => {
  try {
    const orgId = appInfo.org.org_id;
    const endpoint = `notifications?orgId=${orgId}`;
    const data = await getData(endpoint, appInfo.token);

    const filteredNotifications = data.filter((notification) => {
      if (!notification.scheduled_at) {
        return false; // Skip notifications without a scheduled_at date
      }

      const notificationDate = convertFirestoreTimestampToDate(notification.scheduled_at);
      const today = new Date(); // Current date and time

      return scheduled
        ? notificationDate >= today // Include notifications scheduled for today and future
        : notificationDate <= today; // Include notifications for today and past
    });

    // Sort notifications by scheduled_at date in descending order
    const sortedNotifications = filteredNotifications.sort((a, b) => {
      const dateA = convertFirestoreTimestampToDate(a.scheduled_at);
      const dateB = convertFirestoreTimestampToDate(b.scheduled_at);
      return dateB - dateA; // Sort descending
    });

    console.log("Filtered Notifications:", sortedNotifications);
    setNotifications(sortedNotifications);

  } catch (error) {
    console.error("Error fetching notifications:", error);
  }
};


const fetchGyms = async () => {
  try {
    const org_id = appInfo.org.org_id;
    const gymsData = await getData(`gyms?org_id=${org_id}`, appInfo.token);
    setGyms(gymsData);
  } catch (error) {
    console.error("Error fetching gyms:", error);
  }
};

useEffect(() => {
  fetchNotifications();
  fetchGyms();

  const ws = new WebSocket("ws://localhost:8080");

  ws.onopen = () => {
    console.log("WebSocket connected");
  };

  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("WebSocket Data Received:", data);
    if (
      data.type === "NOTIFICATIONS_UPDATED" ||
      data.type === "TEMPLATES_UPDATED"
    ) {
      fetchNotifications();
    }
  };
  

  ws.onerror = (event) => {
    console.error("WebSocket error:", event);
  };

  ws.onclose = () => {
    console.log("WebSocket closed");
  };

  return () => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.close();
    }
  };
}, [appInfo, startDate, endDate, checkedItems, searchTerm]);

const [openNotificationId, setOpenNotificationId] = useState(null);
const toggleNotification = (id) => {
  setOpenNotificationId(openNotificationId === id ? null : id);
};

const convertFirestoreTimestampToDate = (timestamp) => {
  if (timestamp && typeof timestamp === 'object' && '_seconds' in timestamp && '_nanoseconds' in timestamp) {
    const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
    const date = new Date(milliseconds);
    return date;
  }
  return null; // Return null if timestamp is not valid
};


const formatSentTime = (timestamp) => {
  const jsDate = convertFirestoreTimestampToDate(timestamp); // Convert Firestore Timestamp to JavaScript Date
  if (!jsDate) return ""; // Handle invalid date

  let hours = jsDate.getHours();
  let minutes = jsDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Convert 0 hours to 12 for 12 AM
  minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading 0 to minutes if less than 10
  const day = jsDate.getDate();
  const month = jsDate.getMonth() + 1; // Month is 0-indexed
  const year = jsDate.getFullYear();
  return `${month}/${day}/${year} ${hours}:${minutes} ${ampm.toLowerCase()}`;
};


const getGymNames = (gymIds) => {
  if (gymIds.includes(-1)) {
    return "All Gyms";
  }
  return gymIds
    .map((id) => gyms.find((gym) => gym.gym_id === id)?.name || "Unknown")
    .join(", ");
};

return (
  <>
    {notifications.length === 0 ? (
      <div className="flex justify-center items-center h-44 w-[300px] mb-9 mt-[10rem] ml-[25rem]">
        <img src={t} alt="No Data Available" />
      </div>
    ) : (
      <div className="notifications bg-white shadow overflow-hidden sm:rounded-md mx-w-none w-full col-span-12 mt-2">
        <ul className="divide-y-2 divide-gray-200">
          {notifications
            .filter((notification, index) => {
              const isInPage =
                Math.floor(index / itemsPerPage) + 1 === currentPage;
              return isInPage;
            })
            .map((notification) => {
              const receiverInfo = JSON.parse(notification.receiver_info);
              const gymNames = getGymNames(receiverInfo);
              const link = notification.link;
              return (
                <React.Fragment key={notification.id}>
                  <li
                    className="px-2 py-2 whitespace-nowrap cursor-pointer hover:bg-white-100 flex justify-between items-center"
                    onClick={() => toggleNotification(notification.id)}
                  >
                    <div className="flex-1">
                      <p className="text-[12.5px] font-sans-bold text-gray-900">
                        {notification.subject}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="text-right mr-5">
                        <p className="text-[9px] text-gray-500">
                          {formatSentTime(notification.scheduled_at)}
                        </p>
                      </div>
                      <svg
                        className={`w-6 h-8 transform ${
                          openNotificationId === notification.id
                            ? "rotate-180"
                            : "rotate-0"
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </div>
                  </li>
                  {openNotificationId === notification.id && (
                    <div className="p-4 border-t border-gray-200">
                      <p className="text-md text-gray-500">
                        <div className="mb-3">
                          {notification.message.split("Address:")[0]}
                        </div>
                        {link && (
                          <div className="mt-2 text-blue-400 underline">
                            <a href={link} target="_blank" rel="noopener noreferrer">
                              {link}
                            </a>
                          </div>
                        )}
                        {/* <div>
                          Address: {notification.message.split("Address:")[1]}
                        </div> */}
                        <div className="mt-2 font-semibold">Gyms:</div>
                        <div>{gymNames}</div>
                      </p>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </ul>
      </div>
    )}
    {/* Pagination Controls */}
    {totalPages > 0 && (
      <div className="mt-4 flex justify-center items-center">
        <button
          disabled={currentPage === 1}
          className="px-3 py-[7px] bg-gray-500 text-gray-100 rounded-md mr-2"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            className={`px-3 py-[7px] rounded-md mr-2 ${
              number === currentPage
                ? "bg-purple-500 text-white"
                : "bg-gray-500 text-gray-100"
            }`}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          className="px-3 py-[7px] bg-gray-500 text-gray-100 rounded-md"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    )}
  </>
);
};

export default NotificationList;