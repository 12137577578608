import { getAuth, signOut } from "firebase/auth";

function SignOut(navigate, setAppInfo) {
  const auth = getAuth();

  signOut(auth)
    .then(() => {
      console.log("SignOut successful");
      setAppInfo({ user: null, org: null, loggedIn: false });
      localStorage.removeItem("appContext");
      window.location.reload();
      navigate("/");
    })
    .catch((error) => {
      console.log(`SignOut error: ${error}`);
    });
}

export default SignOut;
