// This will have rendering part for the videos module
import React, { useState, useEffect, useContext } from "react";
import VideoUpload from "./video_upload_popup";
import { AppContext } from "../../App";
import ExerciseList from "./exercise-list.view";
import VideosComponent from "./video-component.view";
import { getVideoService } from "./video.service";
import { Route, Routes, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import video_icon from "../../assets/Button UI files/videos_icon_selected.png";

export const Status = Object.freeze({
  NONE: "none",
  PENDING: "pending",
  APPROVED: "approved",
  ALL: "all",
  PENDING_DELETE: "pending_delete",
});

export const StatusDisplay = Object.freeze({
  NONE: "No video",
  PENDING: "Pending Videos",
  APPROVED: "Approved Videos",
  ALL: "All Videos",
  PENDING_DELETE: "Pending Delete",
});

export const StatusMap = Object.freeze({
  "No video": "none",
  "Pending Videos": "pending",
  "Approved Videos": "approved",
  "All Videos": "all",
  "Pending Delete": "pending_delete",
});

const statusOptions = [
  StatusDisplay.ALL,
  StatusDisplay.PENDING,
  StatusDisplay.NONE,
  StatusDisplay.APPROVED,
  StatusDisplay.PENDING_DELETE,
];

function filterVideos(selectedStatus) {
  return (video) => {
    if (selectedStatus === StatusDisplay.PENDING) {
      return video.approval_status === Status.PENDING;
    }
    if (selectedStatus === StatusDisplay.APPROVED) {
      return video.approval_status === Status.APPROVED;
    }
    return true;
  };
}

function Videos() {
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(StatusDisplay.ALL);
  const [UserGyms, setUserGyms] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [gymId, setGymId] = useState("");
  const { appInfo } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const videoService = getVideoService(
    appInfo.token,
    appInfo.userInfo.u_id,
    appInfo.userInfo.org_id,
    appInfo.userInfo.gym_ids
  );

  console.log("Rendering Videos");

  const initData = async () => {
    await videoService
      .getGymData()
      .then((response) => {
        console.warn(response);
        setUserGyms(Array.isArray(response) ? response : [response]);
        if (response.length > 0 && !gymId) {
          setGymId(response[0].gym_id); // Set default gymId
          setLoading(false);
        }
      })
      .then(() => {
        if (gymId !== "") {
          videoService.getExerciseList(gymId, "Use Effect with empty Array");
        }
      })
      .catch((error) => {
        console.log("Error fetching gym details:", error);
      });
  };

  useEffect(() => {
    console.log("Refreshed");
    const uuid_exercise =
      videoService.registerExercisesListListener(setExercises);

    initData();
    return () => {
      videoService.unRegisterExercisesListListener(uuid_exercise);
    };
  }, []);

  useEffect(() => {
    initData();
  }, [gymId]);

  useEffect(() => {}, [showPopup, gymId]);

  return loading ? (
    <p>Loading ...</p>
  ) : (
    <>
      <div className="flex flex-row align-middle py-4 px-2">
        <img src={video_icon} alt="videos" className="w-8 h-8 mt-4" />
        <h1 className="font-extrabold text-3xl pl-6 pb-2 mt-2 text-[#64006c]">
          Instructional Videos
        </h1>
      </div>
      <div
        className="flex h-full relative"
        style={{
          width: "100vw",
          position: "fixed",
          left: "90px",
          backgroundColor: "#f6f5f7",
        }}
      >
        <div
          style={{
            width: "22em",
            minWidth: "22em",
            maxHeight: "90vh",
          }}
        >
          <ExerciseList
            exercises={exercises}
            videoService={videoService}
            handleStatusChange={setSelectedStatus}
          />
        </div>

        <div
          className="relative flex-grow pt-2 pl-4 pb-4 pr-0"
          style={{ marginRight: "-2em" }}
        >
          <div className="absolute top-1 right-40 m-4 flex">
            <div>
              <button
                id="addVideo"
                className="bg-green-500  hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center mr-2"
                style={{ display: "none" }}
                onClick={() => setShowPopup(true)}
              >
                Add Video
                <FontAwesomeIcon icon={faPlus} className="ml-2" />
              </button>
              {showPopup && (
                <VideoUpload
                  gymId={gymId}
                  token={appInfo.token}
                  userId={appInfo.userInfo.u_id}
                  setShowPopup={setShowPopup}
                  showPopup={showPopup}
                  videoService={videoService}
                  roleId={appInfo.userInfo.role_id}
                />
              )}
            </div>

            <select
              className="border rounded-md p-2 text-blue-600"
              onChange={(event) => {
                setGymId(event.target.value);
                console.log("GymId from dropdown", event.target.value);
                setSelectedExercise(null);
              }}
            >
              {UserGyms &&
                UserGyms.map((gym) => (
                  <option key={gym.gym_id} value={gym.gym_id}>
                    {gym.name}
                  </option>
                ))}
            </select>
          </div>

          <Routes basename="videos">
            <Route
              path="/:exerciseId/:status"
              element={
                <VideosComponent
                  roleId={appInfo.userInfo.role_id}
                  gymId={gymId}
                  token={appInfo.token}
                  videoService={videoService}
                  showPopup={showPopup} // this is done to force refresh on closing the popup
                  ExerciseList={exercises}
                  userId={appInfo.userInfo.u_id}
                  gyms={UserGyms}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Videos;
