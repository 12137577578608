import { isExpired } from "react-jwt";

export const isAuthenticated = (setAppInfo = () => {}) => {
  let info;

  try {
    const storedContext = localStorage.getItem("appContext");
    if (storedContext != "undefined") {
      info = JSON.parse(storedContext);
    } else {
      info = null;
    }
  } catch (e) {
    console.error("Error parsing appContext:", e);
    info = null;
  }

  if (info && info.token && info.userInfo && info.loggedIn) {
    if (isExpired(info.token)) {
      console.log("Token Expired");
      localStorage.removeItem("appContext");
      setAppInfo({
        user: null,
        org: null,
        loggedIn: false,
        redprint: null,
        userInfo: null,
      });
      return false;
    } else {
      return true;
    }
  }

  return false;
};

export const setUserData = (data) => {
  localStorage.setItem("appContext", JSON.stringify(data));
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem("appContext"));
};

export const clearUserData = () => {
  localStorage.removeItem("appContext");
};
