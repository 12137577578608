import React, { useState, createContext, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BsFilterSquare } from "react-icons/bs";
import NotificationList from "./NotificationList";
import Button from "./button";
import CreateMessage from "./createmessage";
import Modal from "../../common/modal";
import { AppContext } from "../../App";
import { getData } from "../../common/utils";
import communication_icon from "../../assets/Button UI files/communicate_icon_selected.png";

export const filterContext = createContext();

const Notifications = () => {
  const [showCreateMessage, setShowCreateMessage] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("Sent");
  const [gyms, setGyms] = useState([]);
  const [gym, setGym] = useState(null);
  const [error, setError] = useState(null);
  const { appInfo } = useContext(AppContext);

  // State to keep track of checked items
  const [checkedItems, setCheckedItems] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleCreateMessage = () => {
    setShowCreateMessage(!showCreateMessage);
  };

  const toggleFilterModal = () => {
    if (showFilterModal) {
      // Reset filter state when closing the modal
      handleFilterReset();
    }
    setShowFilterModal(!showFilterModal);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const fetchGyms = async () => {
    try {
      const org_id = appInfo.org.org_id;
      const gymsData = await getData(`gyms?org_id=${org_id}`, appInfo.token);
      setGyms(gymsData);
      setGym(gymsData[0]);
    } catch (error) {
      console.error("Error fetching gyms:", error);
      setError("Error fetching gyms. Please try again later.");
    }
  };

  useEffect(() => {
    // Fetch gyms only once when the component mounts
    fetchGyms();
  }, []);

  const handleCheckboxChange = (gymId) => {
    const isSelected = checkedItems.includes(gymId);
    setCheckedItems((prevSelected) => {
      return isSelected
        ? prevSelected.filter((id) => id !== gymId)
        : [...prevSelected, gymId];
    });
  };

  const handleFilterReset = () => {
    setCheckedItems([]);
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <div className="flex flex-row py-4 px-2">
        <img src={communication_icon} alt="videos" className="w-8 h-8 mt-4" />
        <h1 className="font-extrabold text-3xl pl-6 pb-2 mt-2 text-[#64006c]">
          Communication
        </h1>
      </div>

      <div
        id="messages"
        className="flex flex-col p-3"
        style={{ height: "50rem" }}
      >
        <div className="flex flex-row space-x-4">
          {/* Search bar  */}
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Search..."
              className="border w-full border-gray-300 rounded-sm py-2 px-3  focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={handleChange}
            />
          </div>
          <div>
            <button
              id="addMessage"
              className="w-32 bg-green-500 hover:bg-green-700 text-white h-full p-2 rounded flex items-center text-sm"
              onClick={toggleCreateMessage}
            >
              Create Message
              <FontAwesomeIcon icon={faPlus} className="ml-2" />
            </button>
          </div>

          {/* Filter Modal */}
          <div>
            <BsFilterSquare
              className="w-8 h-9 cursor-pointer"
              onClick={toggleFilterModal}
            />
            {showFilterModal && (
              <div className="absolute right-0 mt-6 mx-w-none w-[245px] bg-white shadow-lg rounded-lg p-4 z-50 border border-black">
                <div className="flex justify-between text-sm">
                  <div className="p-2">Filter</div>
                  <div
                    onClick={handleFilterReset}
                    className="btn cursor-pointer bg-purple-800 hover:bg-purple-600 rounded text-white px-4 py-2"
                  >
                    Reset
                  </div>
                </div>
                <hr className="my-4 border-t border-gray-300 dark:border-gray-300" />
                <div className="mt-4 text-[0.9rem] flex justify-start gap-x-4">
                  <b>Date Range</b>
                </div>
                <div className="mb-2 mt-2 text-sm flex justify-between">
                  <label>Start Date :</label>
                  <input
                    value={startDate}
                    onChange={handleStartDate}
                    className="w-2/3 rounded hover:bg-gray-300"
                    type="date"
                  ></input>
                </div>
                <div className="flex text-sm justify-between">
                  <label>End Date :</label>
                  <input
                    value={endDate}
                    onChange={handleEndDate}
                    className="w-2/3 rounded hover:bg-gray-300"
                    type="date"
                  ></input>
                </div>
                <hr className="my-4 border-t border-gray-300 dark:border-gray-300" />
                <div className="mt-4 text-[0.9rem] flex justify-start gap-x-4">
                  <b>Location</b> <br></br>
                </div>
                <div className="max-w-md mt-3 mx-auto">
                  <ul className="list-none space-y-2">
                    {gyms.map((gym) => (
                      <li key={gym.gym_id} className="flex">
                        <input
                          type="checkbox"
                          className="scale-75 w-[10%] cursor-pointer"
                          id={gym.gym_id}
                          checked={checkedItems.includes(gym.gym_id)}
                          onChange={() => handleCheckboxChange(gym.gym_id)}
                        />
                        <label
                          className="w-2/3 ml-0"
                          style={{ fontSize: "0.75rem" }}
                        >
                          {gym.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        {/*TabsComponent */}
        <div>
          <ul className="flex mt-5  py-1 text-[12.5px] font-sans-bold">
            <li className="-mb-px mr-1">
              <button
                className={`inline-block border-l border-t border-r rounded-t py-2 px-4 text-black ${
                  activeTab === "Sent"
                    ? "bg-white"
                    : "bg-gray-200 hover:bg-purple-200"
                }`}
                onClick={() => setActiveTab("Sent")}
              >
                <div className="inline-block mr-2" />
                Sent
              </button>
            </li>
            <li className="mr-1">
              <button
                className={`inline-block border-l border-t border-r rounded-t py-2 px-4 text-black ${
                  activeTab === "Scheduled"
                    ? "bg-white"
                    : "bg-gray-200 hover:bg-purple-200"
                }`}
                onClick={() => setActiveTab("Scheduled")}
              >
                <div className="inline-block mr-2" />
                Scheduled
              </button>
            </li>
          </ul>
          <div className="pt-4">
            {activeTab === "Sent" && (
              <div>
                <NotificationList
                  startDate={startDate}
                  endDate={endDate}
                  checkedItems={checkedItems}
                  searchTerm={searchTerm}
                  scheduled={false}
                />
              </div>
            )}
            {activeTab === "Scheduled" && (
              <div>
                <NotificationList
                  startDate={startDate}
                  endDate={endDate}
                  checkedItems={checkedItems}
                  searchTerm={searchTerm}
                  scheduled={true}
                />
              </div>
            )}

            {showCreateMessage && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
                <div className="bg-white p-6 rounded-lg w-[750px] max-w-none relative">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-gray-500 cursor-pointer absolute top-4 right-8"
                    onClick={toggleCreateMessage}
                  />
                  <CreateMessage
                    isOpen={showCreateMessage}
                    onClose={toggleCreateMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;