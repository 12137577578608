import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import { AppContext } from "./../App";
import { useNavigate } from "react-router-dom";

const AdminReset = () => {
    const { appInfo, setAppInfo } = useContext(AppContext);
    const navigate = useNavigate();

    const handleResetRedprint = () => {
      setAppInfo(prevAppInfo => {
        const updatedInfo = {
          ...prevAppInfo,
          user: prevAppInfo?.redprint?.engine_user_id,
          userInfo: prevAppInfo?.redprint,
        };
    
        localStorage.setItem('appContext', JSON.stringify(updatedInfo));
    
        return updatedInfo;
      });
    
      window.location.pathname = '/settings';
    }
    
  return (
<div className="grid auto-cols-max gap-4 mt-2 flex-1">
<div className="backCard bg-white justify-between rounded-lg shadow-md p-4 mb-4"  onClick={handleResetRedprint}>
  <button className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2">
    {<FontAwesomeIcon icon={faCircleArrowLeft} />}
  </button>
  <span className="text-md font-medium text-black">
    Back to Redprint Admin View
  </span>
</div>
</div>
  );
};

export default AdminReset;