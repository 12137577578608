import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function Modal({ modalHeader, toggleModal, children, showCloseIcon = true }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      {modalHeader && (
        <div className="bg-white p-6 rounded-lg w-120">
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-semibold">{modalHeader}</h2>
            {showCloseIcon && (
              <FontAwesomeIcon
                icon={faTimes}
                className="text-gray-500 cursor-pointer"
                onClick={toggleModal}
              />
            )}
          </div>
          {children}
        </div>
      )}
      {!modalHeader && (
        <div className="bg-white p-6 rounded-lg w-120 relative">
          <div className="absolute top-6 right-8">
            {showCloseIcon && (
              <FontAwesomeIcon
                icon={faTimes}
                className="text-gray-500 cursor-pointer"
                onClick={toggleModal}
              />
            )}
          </div>
          {children}
        </div>
      )}
    </div>
  );
}

export default Modal;