import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import { addData, deleteData, updateData } from "../../common/utils";
import { AppContext } from "../../App";
import Modal from "../../common/modal";
import Alert from "./Alert";

function GymMaintainence({ orgId, gyms, orgInfo }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await addGym(orgId, gymName, orgInfo);
      if (!appInfo?.redprint) {
        setAlertColor("green");
        setAlertMessage(
          "Thank you. A Redprint admin will review your request and be in touch soon."
        );
      } else {
        setAlertColor("green");
        setAlertMessage("Gym request has been sent");
      }
      setButtonText("OK");
      setShowSuccessAlert(true);
      setIsLoading(false);
    } catch (error) {
      setAlertColor("red");
      setAlertMessage(
        "An error has occurred. Please contact info@redprintfit.com if the problem persists."
      );
      setShowSuccessAlert(true);
      setIsLoading(false);
      return;
    }
  };

  const [gymName, setGymName] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [gymId, setGymId] = useState(-1);
  const [isGymMaintainenceVisible, setIsGymMaintainenceVisible] =
    useState(false);
  const [buttonText, setButtonText] = useState("Add");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [gymIdToDelete, setGymIdToDelete] = useState(null);

  const { appInfo } = useContext(AppContext);

  const handleEditGymName = (id, name) => {
    setGymId(id);
    setGymName(name);
  };

  const handleUpdateGymName = async () => {
    await updateGymDetails(gymId, appInfo?.org?.org_id, gymName);
  };

  const handleGymNameChange = (event) => setGymName(event.target.value);

  const addGym = async (orgId, gymName, orgInfo) => {
    try {
      const body = JSON.stringify({
        gymName: gymName,
        type: orgInfo?.type ?? "private",
      });
      await addData(`gyms?org_id=${orgId}`, body, appInfo.token);
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateGymDetails = async (gymId, orgId, name) => {
    try {
      const data = { name: name };
      const request = JSON.stringify(data);
      const response = await updateData(
        `gyms/${gymId}?org_id=${orgId}`,
        request,
        appInfo.token
      );

      if (!response.ok) {
        throw new Error(response.message || "Failed to update gym details");
      }

      console.log("Gym details updated successfully");
      setGymId(-1);
      gyms.map((gym) => (gym.gym_id === gymId ? { ...gym, name: name } : gym));
      window.location.reload();
    } catch (error) {
      console.error("Error updating gym details:", error);
      return false;
    }
  };

  const deleteGym = async (gymId, orgId) => {
    try {
      const data = { status: 3 };
      const request = JSON.stringify(data);
      const response = await updateData(
        `gyms/${gymId}?org_id=${orgId}`,
        request,
        appInfo.token
      );

      if (!response.ok) {
        throw new Error(response.message || "Failed to delete gym");
      }
      console.log("Deleted Gym:", gymId);
      window.location.reload();
    } catch (error) {
      console.error("Error Deleting gym:", error);
    }
  };

  const toggleGymMaintainenceVisibility = () => {
    setIsGymMaintainenceVisible(!isGymMaintainenceVisible);
  };

  const handleModalClose = (event) => {
    if (buttonText === "OK") {
      event.preventDefault();
      toggleGymMaintainenceVisibility();
      setButtonText("Add");
      setShowSuccessAlert(false);
      setGymName("");
    }
  };

  const handleDelete = (gymId, gymName) => {
    setGymIdToDelete(gymId);
    setGymName(gymName);
    setDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = async (event) => {
    event.preventDefault();
    try {
      setDeleteConfirmation(false);
      await deleteGym(gymIdToDelete, appInfo?.org?.org_id);

      setShowSuccessAlert(true);
      setAlertMessage("Gym deleted successfully.");
    } catch (error) {
      setAlertColor("red");
      setAlertMessage("Unable to delete gym.");
      setShowSuccessAlert(true);
      console.error("Error deleting gym: ", error);
    }
    setGymIdToDelete(null);
    setGymName("");
  };

  return (
    <>
      {isGymMaintainenceVisible && (
        <Modal toggleModal={toggleGymMaintainenceVisibility}>
          {showSuccessAlert && (
            <Alert
              message={alertMessage}
              timeout={3000}
              color={alertColor}
              onClose={() => setShowSuccessAlert(false)}
            />
          )}
          <form onSubmit={handleSubmit}>
            {" "}
            {/* Handle form submission */}
            <div className="grid gap-6">
              <div className="grid grid-cols-3 items-center gap-8">
                <label
                  htmlFor="gymName"
                  className="col-span-1 text-left block text-sm font-medium text-gray-900"
                >
                  Gym name
                </label>
                <input
                  type="text"
                  id="gymName"
                  value={gymName}
                  onChange={handleGymNameChange}
                  className="col-span-2 text-sm font-medium bg-gray-300 w-full"
                  required
                />
              </div>
              <div className="flex items-center justify-center gap-8">
                <button
                  type="submit"
                  onClick={handleModalClose}
                  className={`border-none ${
                    isLoading
                      ? "text-black bg-gray-400"
                      : "text-white bg-purple-900 hover:bg-red-500"
                  } rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
                  disabled={isLoading}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}
      {deleteConfirmation && (
        <Modal toggleModal={() => setDeleteConfirmation(!deleteConfirmation)}>
          <div className="flex flex-col items-center justify-center gap-8">
            <div>Are you sure, you want to delete {gymName} gym?</div>
            <button
              type="submit"
              onClick={handleDeleteConfirmation}
              className={`border-none text-white bg-purple-900 hover:bg-red-500 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={3000}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      <div className="grid  gap-4 flex-1 h-full">
        <div className="gymCard bg-white justify-between rounded-lg shadow-md p-4">
          <div className="gym-maintainence mr-10 mb-4 flex items-center justify-between">
            <p className="text-gray-500">Maintain Gyms</p>
            <button
              style={{ backgroundColor: "#f6f5f7" }}
              onClick={toggleGymMaintainenceVisibility}
              className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-full font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
            >
              {<FontAwesomeIcon icon={faPlus} />}
            </button>
          </div>
          <div className="gym-list overflow-y-auto max-h-[150px]">
            <ul className="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg ">
              {gyms?.map((gym, index) => {
                return (
                  <li
                    key={index}
                    className="w-full px-4 py-2 border-b border-gray-200 flex items-center rounded-t-lg"
                  >
                    {gymId === gym.gym_id ? (
                      <>
                        <input
                          type="text"
                          className="mr-2 border border-gray-900 rounded-lg"
                          value={gymName}
                          onChange={(e) => setGymName(e.target.value)}
                        />
                        <button
                          style={{ backgroundColor: "#f6f5f7" }}
                          onClick={() => handleUpdateGymName(gym?.gym_id)}
                          className="text-black-900 border-none bg-gray-300 hover:bg-purple-00 rounded-full font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
                        >
                          {<FontAwesomeIcon icon={faCheck} />}
                        </button>
                      </>
                    ) : (
                      <>
                        <span className="flex-grow mr-4">{gym?.name}</span>
                        <button
                          style={{ backgroundColor: "#f6f5f7" }}
                          onClick={() => {
                            handleEditGymName(gym?.gym_id, gym?.name);
                          }}
                          className="text-black-900 border-none bg-gray-300 hover:bg-purple-00 rounded-full font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
                        >
                          {<FontAwesomeIcon icon={faPenToSquare} />}
                        </button>
                        <button
                          style={{ backgroundColor: "#f6f5f7" }}
                          onClick={() => {
                            handleDelete(gym?.gym_id, gym?.name);
                          }}
                          className="border-none bg-gray-300 hover:bg-red-300 rounded-full font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
                        >
                          {<FontAwesomeIcon icon={faTrash} />}
                        </button>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default GymMaintainence;
