import React, { useState, useRef, useEffect } from "react";

function MultiSelectDropdown({
  onSelectionChange,
  values,
  preselectedValues = [],
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    //console.log("pre", preselectedValues);
    setSelectedItems(preselectedValues);
  }, [preselectedValues]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    }

    // Unbind the event listener on component unmount
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    //let newSelectedItems = selectedItems.slice();
    let newSelectedItems = [...selectedItems];
    if (checked) {
      //newSelectedItems = setSelectedItems([...selectedItems, value]);
      if (!newSelectedItems.includes(value)) {
        newSelectedItems.push(value);
      }
    } else {
      newSelectedItems = newSelectedItems.filter((item) => item !== value);
    }
    setSelectedItems(newSelectedItems);
    onSelectionChange(newSelectedItems);
  };

  return (
    <div ref={dropdownRef} className="relative">
      <button
        id="dropdownCheckboxButton"
        onClick={toggleDropdown}
        className="text-black bg-gray-300 border-none font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
        type="button"
      >
        Select gyms
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownDefaultCheckbox"
          className="z-10 absolute top-full left-0 mt-2 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow"
        >
          <ul
            className="p-3 space-y-3 text-sm text-gray-700"
            aria-labelledby="dropdownCheckboxButton"
          >
            {values.map((value, valueIndex) => {
              return (
                <li key={value}>
                  <div className="flex items-center">
                    <input
                      id={`checkbox-item-${value}`}
                      type="checkbox"
                      value={value}
                      onChange={handleCheckboxChange}
                      checked={
                        Array.isArray(selectedItems) &&
                        selectedItems.includes(value)
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor={`checkbox-item-${value}`}
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      {value}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MultiSelectDropdown;
