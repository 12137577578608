import React from "react";
import Table from "../../common/table";
import Button from "./button";

function PendingRequestsTable({ requests, handleApprove, handleDecline }) {
  const requestHeaders = [
    "Request number",
    "Organization Name",
    "Gym name",
    "Actions",
  ];
  const requestValues = requests.map((req) => [
    req.r,
    req.org_name ?? "",
    req.gym_name ?? "",
    <>
      <Button
        value="Approve"
        class="text-black-900 border-none bg-gray-300 hover:bg-green-300 rounded-lg font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
        onClick={() => handleApprove(req.gym_id, true, req.org_id)}
      />{" "}
      |{" "}
      <Button
        value="Decline"
        class="text-black-900 border-none bg-gray-300 hover:bg-red-300 rounded-lg font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
        onClick={() => handleDecline(req.gym_id, false, req.org_id)}
      />
    </>,
  ]);

  return (
    <div className="bg-white justify-between rounded-lg shadow-md p-4 max-w-[calc(100%-100px)] overflow-x-auto">
      <div className="requests mr-10 mb-4">
        <p className="text-black font-bold">Pending Requests</p>
      </div>
      <Table headers={requestHeaders} values={requestValues} />
    </div>
  );
}

export default PendingRequestsTable;
