import { Navigate, Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext } from "./App";
import SideBar from "./common/sideBar";
import AdminReset from "./common/adminReset";
import { isExpired } from "react-jwt";
import { isAuthenticated } from "./authentication/authenticateService";

function AppLayout() {
  const { appInfo } = useContext(AppContext);
  return (
    <div className="flex flex-row h-full gap-4 p-2">
      <SideBar roleId={appInfo.userInfo.role_id} />
      <div className="flex-grow ml-24">
        {appInfo.userInfo.role_id != 1 && appInfo.redprint != null && (
          <AdminReset />
        )}
        <Outlet />
      </div>
    </div>
  );
}

function ProtectedRoutes() {
  const { setAppInfo } = useContext(AppContext);
  const isAuth = isAuthenticated(setAppInfo);
  return isAuth ? <AppLayout /> : <Navigate to="/" replace />;
}

export default ProtectedRoutes;
