import React, { useEffect } from "react";

function Alert({ message, onClose, color, timeout }) {
  useEffect(() => {
    if (timeout && onClose) {
      const timer = setTimeout(onClose, timeout);
      return () => clearTimeout(timer);
    }
  }, [onClose, timeout]);

  const colorClasses = {
    green: "text-green-800 bg-green-50",
    red: "text-red-800 bg-red-50",
    yellow: "text-yellow-800 bg-yellow-50",
  };

  const alertClasses = colorClasses[color] || colorClasses.green;

  return (
    <div
      className={`fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50 p-4 mb-4 text-sm ${alertClasses} z-1000`}
      role="alert"
    >
      <span className="font-medium">{message}</span>
    </div>
  );
}

export default Alert;
