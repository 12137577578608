import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { addData, getData } from "../../common/utils";
import { AppContext } from "../../App";
import Alert from "./Alert";

function AddAccessForm({ isAddingAdmin, toggleForm, orgId, name }) {
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [error, setError] = useState(null);

  const addAccess = async (name, email, position) => {
    try {
      const body = {
        name: name,
        email: email,
        gymIds: [],
        roleId: 1,
        position: position,
        orgId: "test_admin_org",
      };
      const request = JSON.stringify(body);
      const response = await addData(`gymUsers`, request, appInfo.token);
      console.log("User access added successfully");
      const emailToken = response.emailToken;

      setSuccessMessage("Success: Access has been added");
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 1100);
      setTimeout(() => {
        toggleForm();
      }, 1300);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return { emailToken };
    } catch (error) {
      console.error("Error adding user:", error);
      const errorMessage = error.message.includes("auth/email-already-in-use")
        ? "Error adding user. Email already exists"
        : "Error adding user. Please try again later.";
      setError(errorMessage);
      return false;
    }
  };

  const addUser = async (data) => {
    try {
      const body = {
        name: data.user_name,
        email: data.email,
        gymIds: [data.gym_id],
        roleId: data.role_id,
        position: data.position,
        orgId: orgId,
      };
      const request = JSON.stringify(body);
      const response = await addData(`gymUsers`, request, appInfo.token);
      if (!response.user.uid) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add user");
      } else {
        console.log("User access added successfully");
        setSuccessMessage("Success: Access has been added");
        setShowSuccessToast(true);
        setTimeout(() => {
          setShowSuccessToast(false);
        }, 1100);
        setTimeout(() => {
          toggleForm();
        }, 1300);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return response;
      }
    } catch (error) {
      console.error("Error adding user:", error);
      // Check if the error message indicates that the email is already in use
      const errorMessage = error.message.includes("auth/email-already-in-use")
        ? "Error adding user. Email already exists"
        : "Error adding user. Please try again later.";
      setError(errorMessage);
      return false;
    }
  };

  const triggerEmail = async (data, userInfo) => {
    try {
      const webHost = process.env.REACT_APP_WEB_URL;
      let body = {
        templateName: "email_verification.hbs",
        data: {
          user: data.user_name,
          organizationName: name ?? "",
          redirectionUrl: `${webHost}verifyEmail?emailToken=${userInfo.emailToken}`,
        },
        subject: "Verify your email",
        toEmail: data.email,
      };
      body = JSON.stringify(body);
      const response = await addData(
        `/emails/triggerEmail`,
        body,
        appInfo.token
      );

      if (!response.message) {
        const errorData = await response.JSON();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = {};
    for (let element of event.target.elements) {
      if (element.name) {
        if (element.name === "fullName") {
          formData["user_name"] = element.value.trim();
        } else if (element.name === "email") {
          formData[element.name] = element.value.trim();
        } else if (element.name === "position") {
          formData[element.name] = element.value.trim();
        }
      }
    }

    if (!formData["user_name"] || !formData["email"] || !formData["position"]) {
      setError("Please fill out all fields.");
      return;
    }

    setError(null);

    formData["role_id"] = 2;
    formData["gym_id"] = "";
    formData["org_name"] = "";
    if (!isAddingAdmin) {
      try {
        await addAccess(
          formData["user_name"],
          formData["email"],
          formData["position"]
        ).then(async (response) => {
          await triggerEmail(formData, response);
        });
        setSuccessMessage("Email sent successfully.");
      } catch (error) {
        console.error("Error adding access or sending email:", error);
        setError("Failed to add access or send email. Please try again.");
      }
    } else {
      try {
        const userInfo = await addUser(formData);
        if (!userInfo.emailVerified) {
          await triggerEmail(formData, userInfo);
          setSuccessMessage("Email sent successfully.");
        }
      } catch (error) {
        console.error("Error sending email:", error);
        setError("Failed to send email. Please try again.");
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-96">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-semibold">
            {isAddingAdmin ? "Add Organization Admin" : "Add Redprint Access"}
          </h2>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-gray-500 cursor-pointer"
            onClick={toggleForm}
          />
        </div>
        {showSuccessToast && (
          <Alert
            message={successMessage}
            color="green"
            onClose={() => setShowSuccessToast(false)}
            timeout={3000}
          />
        )}
        {error && <Alert message={error} color="red" />}
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex items-center">
            <label
              className="block text-sm text-gray-600 w-24 mr-4"
              htmlFor="fullName"
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              className="border border-gray-300 rounded-lg py-1 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4 flex items-center">
            <label
              className="block text-sm text-gray-600 w-24 mr-4"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="border border-gray-300 rounded-lg py-1 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4 flex items-center">
            <label
              className="block text-sm text-gray-600 w-24 mr-4"
              htmlFor="position"
            >
              Position
            </label>
            <input
              type="text"
              id="position"
              name="position"
              className="border border-gray-300 rounded-lg py-1 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="bg-fuchsia-700 text-white rounded-lg float-right py-1 px-2 hover:bg-fuchsia-600 focus:outline-none focus:bg-fuchsia-800"
          >
            {isAddingAdmin ? "Send Invite" : "Add"}
          </button>
        </form>
      </div>
      {showSuccessToast && (
        <Alert
          message={successMessage}
          color="green"
          onClose={() => setShowSuccessToast(false)}
          timeout={3000}
        />
      )}
    </div>
  );
}

export default AddAccessForm;
