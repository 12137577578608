import React, { useEffect, useState } from "react";
import { StatusDisplay } from "./video.view";
// import { StatusDisplay } from "./exercise-list.constants";
import { useNavigate } from "react-router-dom";
export default function ExerciseList({
  exercises,
  videoService,
  handleStatusChange,
}) {
  const statusOptions = [
    StatusDisplay.ALL,
    StatusDisplay.PENDING,
    StatusDisplay.NONE,
    StatusDisplay.APPROVED,
    StatusDisplay.PENDING_DELETE,
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedExercise, setSelectedExercise] = useState(null);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(StatusDisplay.ALL);

  useEffect(() => {
    const firstExercise = getFirstExercise();
    console.warn(firstExercise);
    if (firstExercise) {
      navigate(`/videos/${firstExercise.exercise_id}/${selectedStatus}`);
      const video_approved_count = firstExercise.approved_video_count;
      if (video_approved_count < 11) {
        document.getElementById("addVideo").style.display = "block";
      } else {
        document.getElementById("addVideo").style.display = "none";
      }
    }
    setSelectedExercise(firstExercise);
  }, [selectedStatus, exercises]);

  useEffect(() => {
    if (selectedExercise) {
      navigate(`/videos/${selectedExercise.exercise_id}/${selectedStatus}`);
      const video_approved_count = selectedExercise.approved_video_count;
      if (video_approved_count < 11) {
        document.getElementById("addVideo").style.display = "block";
      } else {
        document.getElementById("addVideo").style.display = "none";
      }
    } else {
      navigate(`/videos`);
      document.getElementById("addVideo").style.display = "none";
    }
  }, [selectedExercise, exercises]);

  function getFirstExercise() {
    return exercises.filter(filterExercise).find((exercise) => true);
  }

  const filterExercise = (item) =>
    item.exercise_name.toLowerCase().startsWith(searchTerm.toLowerCase()) &&
    (selectedStatus === StatusDisplay.ALL ||
      (selectedStatus === StatusDisplay.NONE &&
        item.approved_video_count === 0 &&
        item.pending_video_count === 0 &&
        item.pending_delete_video_count === 0) ||
      (selectedStatus === StatusDisplay.PENDING &&
        item.pending_video_count > 0) ||
      (selectedStatus === StatusDisplay.APPROVED &&
        item.approved_video_count > 0) ||
      (selectedStatus === StatusDisplay.PENDING_DELETE &&
        item.pending_delete_video_count > 0));

  const setStatus = (status) => {
    setSelectedStatus(status);
    handleStatusChange(status);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="border-r rounded-md border-white-300 w-full bg-white m-2 space-y-2">
      <div className="p-2">
        <input
          type="search"
          placeholder="Search exercises"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-2 border rounded-md"
        />
      </div>
      <div className="p-2">
        <div
          key="All videos"
          onClick={() => setStatus(statusOptions[0])}
          className={`p-2 w-full border rounded-md flex justify-center align-middle cursor-pointer ${
            selectedStatus === statusOptions[0]
              ? "bg-[#640071] text-white"
              : "bg-white"
          }`}
        >
          <h1>{statusOptions[0]}</h1>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 p-2">
        {statusOptions.map(
          (status, index) =>
            index !== 0 && (
              <div
                key={index}
                onClick={() => setStatus(status)}
                className={`p-2 border hover:bg-purple-50  rounded-md cursor-pointer ${
                  selectedStatus === status
                    ? "bg-[#640071] text-white"
                    : "bg-white"
                }`}
              >
                {status}
              </div>
            )
        )}
      </div>
      <div className="px-2">
        <p>Search Results</p>
      </div>
      <div
        className="overflow-y-auto pt-4"
        style={{ maxHeight: "calc(100vh - 380px)", overflowWrap: "break-word" }}
      >
        <ul className="list-none p-0 w-full">
          {exercises?.filter(filterExercise)?.map((exercise) => (
            <li
              key={exercise.id}
              onClick={() => setSelectedExercise(exercise)}
              className={`${
                selectedExercise &&
                selectedExercise.exercise_name === exercise.exercise_name
                  ? "bg-purple-50"
                  : ""
              } p-4 cursor-pointer border-b border-gray-300`}
            >
              {exercise?.exercise_name.toUpperCase()}
              <div className="float-right space-x-4">
                {selectedStatus === StatusDisplay.ALL && (
                  <>
                    {exercise.approved_video_count !== 0 && (
                      <span className="text-green-500">
                        {exercise.approved_video_count}
                      </span>
                    )}
                    {exercise.pending_video_count !== 0 && (
                      <span className="text-yellow-500">
                        {exercise.pending_video_count}
                      </span>
                    )}
                    {exercise.approved_video_count === 0 &&
                      exercise.pending_video_count === 0 &&
                      exercise.pending_delete_video_count === 0 && (
                        <span className="text-red-500">0</span>
                      )}
                    {exercise.pending_delete_video_count > 0 && (
                      <span className="text-red-500">
                        {exercise.pending_delete_video_count}
                      </span>
                    )}
                  </>
                )}

                {selectedStatus === StatusDisplay.NONE && (
                  <>
                    {exercise.approved_video_count === 0 &&
                      exercise.pending_video_count === 0 &&
                      exercise.pending_delete_video_count === 0 && (
                        <span className="text-red-500">0</span>
                      )}
                  </>
                )}

                {selectedStatus === StatusDisplay.PENDING && (
                  <>
                    {exercise.pending_video_count > 0 && (
                      <span className="text-yellow-500">
                        {exercise.pending_video_count}
                      </span>
                    )}
                  </>
                )}

                {selectedStatus === StatusDisplay.APPROVED && (
                  <>
                    {exercise.approved_video_count > 0 && (
                      <span className="text-green-500">
                        {exercise.approved_video_count}
                      </span>
                    )}
                  </>
                )}

                {selectedStatus === StatusDisplay.PENDING_DELETE && (
                  <>
                    {exercise.pending_delete_video_count > 0 && (
                      <span className="text-red-500">
                        {exercise.pending_delete_video_count}
                      </span>
                    )}
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
