import React, { useState, useContext, useEffect, useRef } from "react";
import Table from "./../../common/table";
import Modal from "./../../common/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getData, addData, updateData } from "./../../common/utils";
import Alert from "./../Settings/Alert";
import SpinnerInfinity from "./../../common/spinner";
import DropDown from "../../common/dropDown";
import t from "./../../assets/noData/type.png";
import trainer_icon from "./../../assets/Button UI files/trainers_icon_selected.png";

import { AppContext } from "./../../App";

const fetchMembers = async (org_id, gym_id, token) => {
  try {
    const users = await getData(
      `users?orgId=${org_id}&gymId=${gym_id}&roleId=${5}`,
      token
    );
    return users;
  } catch (error) {
    console.error("Error fetching stats:", error);
  }
};

const fetchTrainers = async (org_id, gym_id, token) => {
  try {
    const users = await getData(
      `users/trainers?orgId=${org_id}&gymId=${gym_id}`,
      token
    );
    return users;
  } catch (error) {
    console.error("Error fetching stats:", error);
  }
};
function UserTable() {
  const ulRef = useRef();
  const inputRef = useRef();
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [trainers, setTrainers] = useState([]);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchMemberQuery, setMemberSearchQuery] = useState("");
  const [gyms, setGyms] = useState(null);
  const [gym, setGym] = useState(null);
  const [error, setError] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [trainerToDelete, setTrainerToDelete] = useState({
    userId: null,
    userName: "",
  });

  const [searchTrainerQuery, setTrainerSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableValues, setTableValues] = useState(null);
  const [paginationNumbers, setPaginationNumbers] = useState(null);
  const itemsPerPage = 9;

  const fetchGyms = async () => {
    try {
      const org_id = appInfo.org.org_id;
      const gymsData = await getData(`gyms?org_id=${org_id}`, appInfo.token);
      setGyms(gymsData);
      setGym(gymsData[0]);
    } catch (error) {
      console.error("Error fetching gyms:", error);
      setError("Error fetching gyms. Please try again later.");
    }
  };
  const updateUserRole = async (userId, roleId) => {
    try {
      const body = {
        gymId: gym.gym_id,
        roleId: roleId,
      };
      const request = JSON.stringify(body);
      const response = await updateData(
        `gymUsers/userRole/${userId}`,
        request,
        appInfo.token
      );
      if (!response.ok) {
        throw new Error(response.message || "Failed to update user role");
      }

      console.log("updated successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error updating admin details:", error);
      setError("Error updating role. Please try again later.");
      return false;
    }
  };

  useEffect(() => {
    if (gym) {
      setCurrentPage(1);
      fetchTrainers(appInfo.org.org_id, gym.gym_id, appInfo.token).then(
        (result) => {
          let t = result.filter((u) => u.role_id == 4);
          setTrainers(t);
          const tP = Math.ceil(t.length / itemsPerPage);
          const startIndex = 0;
          const endIndex = startIndex + itemsPerPage;
          const paginatedUsers = t.slice(startIndex, endIndex);
          //todo get the counts properly
          setTableValues(
            paginatedUsers.map((user) => {
              console.log("user", user);
              return [
                user.name,
                //TODO :  Add below columns when the features are implemented.
                // user.lastVisited ?? getRandomDate(),
                user.videosCount ?? 0,
                // user.programDownloads ?? getRandomNumber(0, 50),
                <button onClick={() => handleDeleteUser(user.u_id, user.name)}>
                  {<FontAwesomeIcon icon={faTrash} />}
                </button>,
              ];
            })
          );
          setPaginationNumbers(
            Array.from({ length: tP }, (_, index) => index + 1)
          );
          setTotalPages(tP);
          setIsLoading(false);
        }
      );
      fetchMembers(appInfo.org.org_id, gym.gym_id, appInfo.token).then(
        (result) => {
          setMembers(result);
        }
      );
    }
  }, [gym]);

  useEffect(() => {
    fetchGyms();
  }, [appInfo]);

  const handleDeleteUser = (userId, userName) => {
    setDeleteConfirmation(true);
    setTrainerToDelete({ userId, userName });
  };

  const handleDeleteConfirmation = async () => {
    try {
      setDeleteConfirmation(false);
      await updateUserRole(trainerToDelete.userId, 5);

      setTrainers(
        trainers.filter((user) => user.id !== trainerToDelete.userId)
      );

      setAlertMessage("Trainer deleted successfully.");
      setShowSuccessAlert(true);
    } catch (error) {
      setAlertColor("red");
      setAlertMessage("Unable to delete trainer.");
      setShowSuccessAlert(true);
      console.log("Unable to delete trainer - ", error);
    }

    setTrainerToDelete({ userId: null, userName: "" });
  };

  const handleViewDetails = () => {
    setOpenModal(true);
  };

  const handleTrainerSearchChange = (event) => {
    setTrainerSearchQuery(event.target.value);
  };
  const handleMemberSearchChange = (event) => {
    setMemberSearchQuery(event.target.value);
  };

  const triggerEmail = async (data) => {
    try {
      console.log(data);
      const webHost = process.env.REACT_APP_WEB_URL || "http://localhost:3000/";

      let body = {
        templateName: "trainer_template.hbs",
        data: {
          senderName: appInfo.userInfo.name,
          organizationName: appInfo.org.name,
          loginUrl: `${webHost}`,
        },
        subject: "You’ve Been Added as a Trainer on Redprint’s Web Portal",
        toEmail: data.email,
      };

      body = JSON.stringify(body);
      const response = await addData(
        `/emails/triggerEmail`,
        body,
        appInfo.token
      );

      if (!response.message) {
        const errorData = await response.JSON();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleAddTrainer = async (user) => {
    console.log("user", user);
    try {
      if (user.u_id) {
        updateUserRole(user.u_id, 4);
      }
      await triggerEmail(user);
    } catch (error) {
      setAlertColor("red");
      setAlertMessage(
        "An error has occurred. Please contact info@redprintfit.com if the problem persists."
      );
      setShowSuccessAlert(true);
      return;
    }
  };
  const handleCancelAddTrainer = () => {
    setOpenModal(false);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleGymSelection = (gymId) => {
    setGym(gyms[gymId]);

    console.log("Selected gym:", gyms[gymId]);
  };
  const tableHeaders = [
    "Name",
    //TODO :  Add below columns when the features are implemented.
    // "Last Visited",
    "Uploaded Videos",
    // "Program Downloads",
    "Actions",
  ];

  useEffect(() => {
    let filteredTrainers = trainers.filter((user) =>
      user.name.toLowerCase().includes(searchTrainerQuery.toLowerCase())
    );
    const tP = Math.ceil(filteredTrainers.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUsers = filteredTrainers.slice(startIndex, endIndex);

    const remainingItems = paginatedUsers.length % itemsPerPage;
    const buffersNeeded =
      remainingItems === 0 ? 0 : itemsPerPage - remainingItems;

    const tableValues = paginatedUsers.map((user) => [
      user.name,
      //TODO :  Add below columns when the features are implemented.
      // user.lastVisited ?? getRandomDate(),
      user.videosCount ?? 0,
      // user.programDownloads ?? getRandomNumber(0, 50),
      <button onClick={() => handleDeleteUser(user.u_id, user.name)}>
        {<FontAwesomeIcon icon={faTrash} />}
      </button>,
    ]);

    for (let i = 0; i < buffersNeeded; i++) {
      tableValues.push(["", "", "", "", ""]);
    }

    setTableValues(tableValues);
    setPaginationNumbers(Array.from({ length: tP }, (_, index) => index + 1));
    setTotalPages(tP);
  }, [searchTrainerQuery, currentPage]);

  return (
    <>
      {deleteConfirmation && (
        <Modal toggleModal={() => setDeleteConfirmation(!deleteConfirmation)}>
          <div className="flex flex-col items-center justify-center gap-8">
            <div>
              Are you sure, you want to delete the trainer{" "}
              {trainerToDelete.userName}?
            </div>
            <button
              type="submit"
              onClick={handleDeleteConfirmation}
              className={`border-none text-white bg-purple-900 hover:bg-red-500 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
      <div className="p-6 h-screen">
        {showSuccessAlert && (
          <Alert
            message={alertMessage}
            timeout={9000}
            color={alertColor}
            onClose={() => setShowSuccessAlert(false)}
          />
        )}
        <div className="flex flex-row align-middle py-4 px-2">
          <img src={trainer_icon} alt="trainers" className="w-8 h-8 mt-4" />
          <h1 className="font-extrabold text-3xl pl-6 pb-2 mt-2 text-[#64006c]">
            Trainer Management
          </h1>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTrainerQuery}
            onChange={handleTrainerSearchChange}
            className="p-2 border rounded mr-2 flex-grow"
          />

          {gyms != null && gyms.length > 0 && (
            <div className="pr-2">
              <DropDown
                options={gyms.map((gym) => gym.name)}
                onSelect={handleGymSelection}
              />
            </div>
          )}
          <button
            type="button"
            className="inline-flex justify-center w-32 rounded-md border font-bold  shadow-sm px-4 py-2 bg-green-500 text-sm hover:bg-green-700  text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={() => handleViewDetails()}
          >
            Add Trainer
            <div className="ml-1">
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </button>
        </div>
        {isLoading ? (
          <SpinnerInfinity
            stroke={appInfo.org.branding_colors[0]}
            size={18}
            color={appInfo.org.branding_colors[1]}
            pTop={10}
            pLeft={0}
            className=" col-start-2 mt-[10rem] ml-[33rem]"
          />
        ) : tableValues.length > 0 ? (
          <Table headers={tableHeaders} values={tableValues} headerSize="4/5" />
        ) : (
          <img src={t} alt="logo" className="h-80 ml-[30rem]" />
        )}
        {!isLoading && tableValues.length > 0 && (
          <div className="mt-4 flex justify-center items-center">
            <button
              disabled={currentPage === 1}
              className="px-3 py-[7px] bg-gray-500 text-gray-100 rounded-md mr-2"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            {paginationNumbers?.map((number) => (
              <button
                key={number}
                className={`px-3 py-[7px] rounded-md mr-2 ${
                  number === currentPage
                    ? "bg-purple-500 text-white"
                    : "bg-gray-500 text-gray-100"
                }`}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </button>
            ))}
            <button
              disabled={currentPage === totalPages}
              className="px-3 py-[7px] bg-gray-500 text-gray-100 rounded-md ml-2"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        )}
        {/* Modal */}
        {openModal && (
          <Modal modalHeader="Add Trainer" toggleModal={handleCancelAddTrainer}>
            <div className="flex flex-col h-96">
              {/* Search input */}

              <label className="flex flex-row gap-8">
                <input
                  type="text"
                  value={searchMemberQuery}
                  onChange={handleMemberSearchChange}
                  ref={inputRef}
                  placeholder="Search users..."
                  className="p-2 border rounded w-96"
                />
              </label>
              {/* Display filtered options */}
              <div
                ref={ulRef}
                className=" border-t-2 border-l-2 border-r-2 border-b-2 p-2 mt-2 rounded h-72 max-h-72 overflow-y-auto"
              >
                {members
                  .filter((user) => {
                    return (
                      user.name
                        .toLowerCase()
                        .includes(searchMemberQuery.toLowerCase()) ||
                      user.email
                        .toLowerCase()
                        .includes(searchMemberQuery.toLowerCase())
                    );
                  })
                  .map((user, index) => (
                    <div
                      key={user.u_id || index}
                      className="submit cursor-pointer hover:bg-gray-200 p-2 flex justify-between"
                      onClick={() => handleAddTrainer(user)}
                    >
                      <div className="flex flex-col">
                        <span className="font-bold">{user.name} </span>
                        <span className="text-sm">{`@${user.name}`}</span>
                      </div>
                      <div className="text-sm flex justify-center items-center truncate max-w-xs overflow-hidden whitespace-nowrap">
                        {user.email.trim() !== "" ? user.email : ""}
                      </div>
                      <div className="flex justify-center items-center ">
                        {<FontAwesomeIcon icon={faPlus} />}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default UserTable;
